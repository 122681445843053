import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './success.module.css'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
    <p className={styles.centerText}>
      Success! Thank you for your message - we will get back to you shortly!
    </p>
    </main>
    <Footer />
  </Layout>
)
